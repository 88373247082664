import React from "react";
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	ResponsiveContainer,
} from "recharts";

export default function CustomRadarChart(props) {
	const data = [
		{
			rating: "0",
			A: 5,
			fullMark: 4,
		},
		{
			rating: "1",
			A: 4,
			fullMark: 4,
		},
		{
			rating: "2",
			A: 3,
			fullMark: 4,
		},
		{
			rating: "3",
			A: 2,
			fullMark: 4,
		},
		{
			rating: "4",
			A: 1,
			fullMark: 4,
		},
		{
			rating: "5",
			A: 5,
			fullMark: 4,
		},
	];

	return (
		<ResponsiveContainer width="100%" height="100%">
			<RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
				<PolarGrid />
				<PolarAngleAxis dataKey="rating" />
				<PolarRadiusAxis />
				<Radar
					name="Mike"
					dataKey="A"
					stroke="#8884d8"
					fill="#8884d8"
					fillOpacity={0.6}
				/>
			</RadarChart>
		</ResponsiveContainer>
	);
}
